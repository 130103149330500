<template>
  <div class="customer">
    <v-card>
      <v-card-title>
        Upcoming Customer Expiry
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isUsersRefreshing"
                @click="refreshCustomers()"
              />
            </v-col>
            <v-col class="col-md-6 text-md-right">
              <table-filter
                :show-date-filter="false"
                :show-expiry-date-filter="true"
                :initial-filters="filters"
                :label="filterLabel"
                :table-name="'customers expiring'"
                @filter="applyFilters"
                @filterLabelChange="onFilterLabelChange"
              />
            </v-col>
            <v-col class="col-md-3 text-md-right">
              <export-button
                :title="exportTitle"
                :headers="exportHeaders"
                :data-endpoint="constructCompleteCustomersUrl"
                :orientation="'landscape'"
              />
            </v-col>
          </v-row><v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-expand-transition>
        <v-row
          v-if="selected.length > 0"
          class="mb-2 ml-2"
        >
          <v-col class="">
            <v-menu offset-y>
              <!-- Trigger button -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  Actions
                  <v-icon right>
                    {{ icons.mdiMenuDown }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- Existing menu item for sending a welcome message -->
                <v-list-item
                  :disabled="isSendingPortalCredentialsMessage"
                  @click="showPortalCredentialsMessageDialog = true"
                >
                  <v-list-item-title>Send portal credentials message [{{ selected.length }}]</v-list-item-title>
                </v-list-item>

                <!-- New menu item for deleting selected items -->
                <v-list-item
                  v-if="selected.length > 0 && can('customer-delete')"
                  @click="showConfirmBulkDeleteDialog = true"
                >
                  <v-list-item-title>
                    Delete [{{ selected.length }}]
                  </v-list-item-title>
                </v-list-item>
                <!-- Add more items here as needed -->
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="customers"
        :loading="isUsersLoading"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        :footer-props="footerProps"
        class="clickable-data-table"
        show-select
        @click:row="onRowClick"
      >
        <template v-slot:item.phone_number="{ item }">
          {{ item.phone_number }}
        </template>
        <template v-slot:item.account_number="{ item }">
          {{ item.customer.account_number }}
        </template>
        <template v-slot:item.internet_connection_type="{ item }">
          {{ item.customer.internet_connection_type | formatInternetConnection(internetConnectionTypes) }}
        </template>
        <template v-slot:item.customer.customer_plan.internet_plan.name="{ item }">
          <template v-if="item.customer.customer_plan">
            <span>{{ item.customer.customer_plan.internet_plan.name }} ({{ formatValidity(item.customer.customer_plan.internet_plan.validity_unit, item.customer.customer_plan.internet_plan.validity) }})</span>
            <span v-if="item.customer.has_pending_plan_change && item.customer.pending_plan_change">
              (Pending: {{ item.customer.pending_plan_change.new_plan.name }})
            </span>
          </template>
        </template>
        <template v-slot:item.customer.customer_plan.effective_end_date="{ item }">
          <span v-if="item.customer.customer_plan">
            <p
              class="mb-2"
              :style="getExpiryColor(item.customer.customer_plan.effective_end_date)"
            >
              <template v-if="item.customer.customer_plan.end_date !== item.customer.customer_plan.effective_end_date">
                <!-- Strikethrough on original end_date -->
                <span class="strikethrough">{{ item.customer.customer_plan.end_date | formatDate }}</span>
                <br>
                <!-- Show effective end date -->
                <span>{{ item.customer.customer_plan.effective_end_date | formatDate }}</span>
                <!-- Tooltip for explanation -->
                <v-tooltip
                  max-width="300"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      style="cursor: pointer;"
                      v-on="on"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span v-html="expiryDateTooltipMessage(item.customer.customer_plan.end_date, item.customer.customer_plan.effective_end_date)"></span>
                </v-tooltip>
              </template>
              <!-- Otherwise, show the standard end date -->
              <template v-else>
                {{ item.customer.customer_plan.end_date | formatDate }}
              </template>
            </p>
          </span>
          <v-btn
            v-if="item.customer.customer_plan && item.customer.can_update_plan_expiry"
            color="primary"
            class="mb-1"
            small
            outlined
            @click.stop="updateExpiry(item)"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiUpdate }}
            </v-icon>
            Update Expiry
          </v-btn>
        </template>
        <template v-slot:item.customer.has_internet_access="{ item }">
          <v-chip
            class="ma-2"
            :color="item.customer.has_internet_access ? 'success' : 'error'"
            dark
          >
            {{ item.customer.has_internet_access ? 'Yes' : 'No' }}
          </v-chip>
        </template>
        <template v-slot:item.customer.debt="{ item }">
          {{ item.customer.debt | formatCurrency }}
        </template>
        <template v-slot:item.customer.station.name="{ item }">
          {{ item.customer.station.name }}
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiPlus, mdiDownloadCircleOutline, mdiImport, mdiUpdate, mdiMenuDown, mdiOpenInNew, mdiInformationOutline,
} from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import ExportButton from '@/components/partials/ExportButton.vue'
import billingIntervalName from '@/mixins/billingIntervalName'
import internetConnectionTypes from '@/mixins/internetConnectionTypes'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'
import hasPermission from '@/mixins/hasPermission'
import PortalCredentialsMessageMixin from '@/mixins/portalCredentialsMessageMixin'

export default {
  components: {
    TableFilter,
    SearchInput,
    RefreshButton,
    ExportButton,
  },
  mixins: [billingIntervalName, internetConnectionTypes, internetConnectionTypeEnum, hasPermission, PortalCredentialsMessageMixin],
  data() {
    return {
      internetConnectionType: this.$route.query.internet_connection_type,
      search: '',
      pagination: {
        totalItems: 0,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },
      options: {
        sortBy: ['customer.customer_plan.effective_end_date'],
        sortDesc: [false],
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      selected: [],
      filters: {
        expiryDates: {
          fromDate: '',
          toDate: '',
        },
        customerStatus: {
          activePlan: false,
          expiredPlan: false,
          internetAccess: false,
          noInternetAccess: false,
          debt: false,
          noDebt: false,
          staticIp: false,
          pppoe: false,
          dynamicIp: false,
          hotspot: false,
          noInternetPlan: false,
          noPhoneNumber: false,
        },
      },
      filterLabel: 'Next 3 days',
      searchColumn: 'name',
      searchFilters: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Phone number',
          value: 'phone_number',
        },
        {
          text: 'Account Number',
          value: 'customer.account_number',
        },
        {
          text: 'Address',
          value: 'customer.address',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiPlus,
        mdiDownloadCircleOutline,
        mdiImport,
        mdiUpdate,
        mdiMenuDown,
        mdiOpenInNew,
        mdiInformationOutline,
      },
      user: {},
      customers: [],
      customersToDownload: [],
      customersToDownloadFileName: '',
      customerUrl: 'customers',
      isUsersLoading: true,
      showAddCustomerInternetPlanDialog: false,
      showUpdateExpiryDialog: false,
      showConfirmDeleteDialog: false,
      showConfirmBulkDeleteDialog: false,
      showImportDialog: false,
      showConfigurationDetails: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBulkDeleteDialogButtonLoading: false,
      isUsersRefreshing: false,
      isDownloadCsvButtonLoading: false,
      toDelete: null,
      toDeleteCustomerName: null,
      customerId: this.$route.params.id,
      exportTitle: 'Customers',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Customers',
          disabled: true,
          to: { name: 'customers' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    refreshTable() {
      return `${this.selectedStation}|${this.customerUrl}|${this.search}`
    },
    deleteUserMessage() {
      return `Are you sure you want to delete ${this.toDeleteCustomerName} from customers?`
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown
    },
    headers() {
      const headers = [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Phone number',
          value: 'phone_number',
          sortable: false,
        },
        { text: 'Account Number', value: 'customer.account_number', sortable: false },
        { text: 'Internet Plan', value: 'customer.customer_plan.internet_plan.name', sortable: false },
        { text: 'Expiry Date', value: 'customer.customer_plan.effective_end_date', sortable: true },
      ]

      // Conditionally add the Debt column
      if (this.filters.customerStatus.debt) {
        headers.splice(headers.length - 1, 0, { text: 'Debt', value: 'customer.debt', sortable: false })
      }

      // Conditionally add the Credits column
      if (this.filters.customerStatus.credits) {
        headers.splice(headers.length - 1, 0, { text: 'Credits', value: 'customer.credits', sortable: false })
      }

      // Conditionally add the Internet Access column
      if (this.filters.customerStatus.internetAccess) {
        headers.splice(headers.length - 2, 0, { text: 'Internet Access', value: 'customer.has_internet_access', sortable: false })
      }

      if (this.selectedStation === 'all') {
        headers.splice(headers.length - 1, 0, { text: 'Station', value: 'customer.station.name' })
      }

      return headers
    },
    exportHeaders() {
      const exportHeaders = this.headers.filter(header => header.value !== 'actions')

      if (!exportHeaders.some(header => header.value === 'customer.debt')) {
        exportHeaders.push({ text: 'Debt', value: 'customer.debt' })
      }
      if (!exportHeaders.some(header => header.value === 'customer.credits')) {
        exportHeaders.push({ text: 'Credits', value: 'customer.credits' })
      }
      if (!exportHeaders.some(header => header.value === 'customer.ip_address')) {
        exportHeaders.push({ text: 'Ip Address', value: 'customer.ip_address' })
      }
      if (!exportHeaders.some(header => header.value === 'customer.mac_address')) {
        exportHeaders.push({ text: 'Mac Address', value: 'customer.mac_address' })
      }

      return exportHeaders
    },
    constructCompleteCustomersUrl() {
      const sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'

      const params = new URLSearchParams({
        stationId: 'all',
        sortBy: this.options.sortBy[0],
        sortDirection: sortOrder,
        search: this.search,
        searchColumn: this.searchColumn,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        activePlan: this.filters.customerStatus.activePlan,
        expiredPlan: this.filters.customerStatus.expiredPlan,
        internetAccess: this.filters.customerStatus.internetAccess,
        noInternetAccess: this.filters.customerStatus.noInternetAccess,
        debt: this.filters.customerStatus.debt,
        noDebt: this.filters.customerStatus.noDebt,
        exemptedFromDisconnection: this.filters.customerStatus.exemptedFromDisconnection,
        credits: this.filters.customerStatus.credits,
        staticIp: this.filters.customerStatus.staticIp,
        pppoe: this.filters.customerStatus.pppoe,
        dynamicIp: this.filters.customerStatus.dynamicIp,
        hotspot: this.filters.customerStatus.hotspot,
        dormant: this.filters.customerStatus.dormant,
        noInternetPlan: this.filters.customerStatus.noInternetPlan,
        noPhoneNumber: this.filters.customerStatus.noPhoneNumber,
        expiryDateFrom: this.filters.expiryDates?.fromDate,
        expiryDateTo: this.filters.expiryDates?.toDate,
      })

      return `${this.customerUrl}?${params.toString()}`
    },

  },
  watch: {
    options: {
      handler() {
        this.getCustomers()
      },
      deep: true,
    },
    refreshTable() {
      this.getCustomers()
    },
    searchColumn() {
      if (this.search !== '') {
        this.getCustomers()
      }
    },
  },
  mounted() {
    this.getCustomers()
  },
  methods: {
    ...mapActions(['setSelectedStation', 'refreshStations']),
    initializeBreadcrumbs() {
      if (this.internetConnectionType === 'all') {
        this.breadcrumbs.push({
          text: 'All',
          disabled: true,
          to: { name: 'internet-plans' },
        })
        this.showConfigurationDetails = true
      } else {
        this.updateBreadcrumbsForConnectionType()
      }
    },
    updateBreadcrumbsForConnectionType() {
      const internetConnectionType = parseInt(this.internetConnectionType, 10)
      const filterMapping = {
        [internetConnectionTypeEnum.PPPOE]: 'pppoe',
        [internetConnectionTypeEnum.DYNAMIC]: 'dynamicIp',
        [internetConnectionTypeEnum.STATIC]: 'staticIp',
        [internetConnectionTypeEnum.HOTSPOT]: 'hotspot',
      }

      // Reset all connection type filters to false
      Object.keys(this.filters.customerStatus).forEach(key => {
        this.filters.customerStatus[key] = false
      })

      if (filterMapping[internetConnectionType]) {
        this.filters.customerStatus[filterMapping[internetConnectionType]] = true
        this.filterLabel = `customers with ${filterMapping[internetConnectionType]}`
      } else {
        console.log('Unknown internet connection type:', internetConnectionType)
      }

      this.breadcrumbs.push({
        text: this.getEnumKeyByValue(internetConnectionTypeEnum, internetConnectionType),
        disabled: true,
        to: { name: 'internet-plans', params: { internet_connection_type: internetConnectionType } },
      })
    },
    getCustomers: _.debounce(function () {
      this.isUsersLoading = true
      axios
        .get(this.constructCompleteCustomersUrl)
        .then(response => {
          this.customers = response.data.data
          this.pagination.totalItems = response.data.total
          this.isUsersLoading = false
          this.isUsersRefreshing = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.isUsersRefreshing = false
          this.$toast.error(error.response.data.message)
        })
    }, 500),
    refreshCustomers() {
      this.isUsersRefreshing = true
      this.getCustomers()
    },
    deleteUser() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`customers/${this.toDelete}`)
        .then(() => {
          this.refreshCustomers()
          this.$toast.success('Customer deleted successfully')
        }).catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    deleteSelectedUsers() {
      this.isConfirmBulkDeleteDialogButtonLoading = true
      const selectedIds = this.selected.map(customer => customer.id)
      axios
        .delete('bulk-delete/customers', {
          data: {
            ids: selectedIds,
          },
        })
        .then(() => {
          this.selected = []
          this.getCustomers()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isConfirmBulkDeleteDialogButtonLoading = false
          this.showConfirmBulkDeleteDialog = false
        })
    },
    sendPortalCredentialsMessage() {
      const selectedIds = this.selected.map(user => user.customer.id)
      this.confirmAndSendPortalCredentialsMessagesToCustomers(selectedIds)
    },
    expiryDateTooltipMessage(endDate, effectiveEndDate) {
      const { formatDate } = this.$options.filters
      if (endDate !== effectiveEndDate) {
        return `The original expiry date was set to ${formatDate(endDate)}, but a disconnection exemption has been applied, resulting in a new effective expiry date of ${formatDate(effectiveEndDate)}.`
      }

      return ''
    },
    applyFilters(filters) {
      this.filters = filters
      this.getCustomers()
    },
    getExpiryColor(date) {
      const currentDate = new Date()
      const expiryDate = new Date(date)

      return expiryDate.getTime() > currentDate.getTime() ? 'color: var(--v-success-base)' : 'color: var(--v-error-base)'
    },
    addInternetPlan(customer) {
      this.user = customer
      this.showAddCustomerInternetPlanDialog = true
    },
    updateExpiry(customer) {
      this.user = customer
      this.showUpdateExpiryDialog = true
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    getEnumKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value)
    },
    openInNew(item) {
      const routeData = this.$router.resolve({
        name: 'customers.show',
        params: { id: item.id },
      })
      window.open(routeData.href, '_blank')
    },
    downloadCSV() {
      this.isDownloadCsvButtonLoading = true
      axios
        .get(`download-customers?station_id=${this.selectedStation}`)
        .then(response => {
          if (response.data.customers.length > 0) {
            this.customersToDownloadFileName = `${response.data.filename}.csv`
            this.$toast.success('Your download will start shortly')
            this.isDownloadCsvButtonLoading = false
            this.customersToDownload = response.data.customers
            this.$refs.export.data = response.data.customers
            this.$refs.export.name = `${response.data.filename}.csv`
            this.$refs.export.generate()
          } else {
            this.$toast.error('No customers to download')
            this.isDownloadCsvButtonLoading = false
          }
        })
        .catch(error => {
          console.error(error)
          this.$toast.error(error.response.data.message)
          this.isDownloadCsvButtonLoading = false
        })
    },
    onFilterLabelChange(label) {
      this.exportTitle = label
    },
    onUserAdded() {
      this.refreshCustomers()
    },
    onUserUpdated() {
      this.refreshCustomers()
    },
    onImportedCustomers() {
      this.refreshCustomers()
    },
    onRowClick(item) {
      this.$router.push({ name: 'customers.show', params: { id: item.id } })
    },
    removeActionsHeader() {
      this.headers.pop()
    },
  },
}
</script>

<style scoped>
.strikethrough {
  text-decoration: line-through;
  color: #9E9E9E; /* Gray color for the original price */
  margin-right: 10px;
}
</style>
