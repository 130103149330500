<template>
  <v-row v-if="isLowCredit">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <span v-if="smsCredits">
          SMS credits are running low! Current balance is {{ smsCredits.creditsBalance }} (Threshold: {{ smsCredits.threshold }}).<br>
          Please top up to ensure uninterrupted SMS service.
          <span v-if="smsCredits.topUpDetails && smsCredits.topUpDetails.paybillNumber && smsCredits.topUpDetails.accountNumber">
            <br>Top-up details:
            <ul>
              <li>Paybill Number: {{ smsCredits.topUpDetails.paybillNumber }}</li>
              <li>Account Number: {{ smsCredits.topUpDetails.accountNumber }}</li>
            </ul>
            {{ smsCredits.topUpDetails.message }}
          </span>
        </span>
        <span v-else>
          Unable to fetch SMS credit information. Please check the system.
        </span>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LowSmsCreditAlert',

  data() {
    return {
      smsCredits: null,
      isLowCredit: false,
    }
  },

  mounted() {
    this.fetchSmsCredits()
  },

  methods: {
    async fetchSmsCredits() {
      try {
        const { data } = await axios.get('sms/low-credit-balance')
        this.smsCredits = data
        this.isLowCredit = data.isBelowThreshold
      } catch (error) {
        console.error('Error fetching SMS credit balance:', error)
        this.smsCredits = null
        this.isLowCredit = false
      }
    },
  },
}
</script>

<style scoped>
.v-alert {
  margin-top: 16px;
}
</style>
