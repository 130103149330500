<template>
  <v-row v-if="hasInvoice && can('service-charge-invoice-list')">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p>{{ invoiceMessage }}</p>
        <v-btn
          color="accent"
          class="ml-2"
          :loading="isLoadingViewInvoice"
          :disabled="isLoadingViewInvoice"
          @click="handleViewInvoice"
        >
          <v-icon left>
            {{ icons.view }}
          </v-icon>
          {{ viewInvoiceButtonText }}
        </v-btn>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiEye } from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'

export default {
  mixins: [hasPermission],
  data() {
    return {
      hasInvoice: false,
      invoiceMessage: '',
      pendingInvoices: [],
      totalAmountExpected: 0,
      isLoadingViewInvoice: false,
      icons: {
        view: mdiEye,
      },
    }
  },
  computed: {
    viewInvoiceButtonText() {
      return this.pendingInvoices.length > 1 ? 'View Invoices' : 'View Invoice'
    }
  },
  mounted() {
    this.fetchLatestInvoice()
  },
  methods: {
    fetchLatestInvoice() {
      axios.get('/latest-service-charge-invoice')
        .then(response => {
          const { pending_invoices, total_amount_expected } = response.data
          if (pending_invoices.length > 0) {
            this.pendingInvoices = pending_invoices
            this.totalAmountExpected = total_amount_expected
            this.hasInvoice = true
            this.prepareInvoiceMessage()
          } else {
            this.hasInvoice = false
          }
        })
        .catch(error => {
          console.error('Error fetching latest invoice:', error)
        })
    },
    prepareInvoiceMessage() {
      if (this.pendingInvoices.length === 1) {
        const invoice = this.pendingInvoices[0]
        const invoiceDate = new Date(invoice.created_at)
        invoiceDate.setMonth(invoiceDate.getMonth() - 1)
        const invoiceMonth = invoiceDate.toLocaleString('default', { month: 'long' })
        const invoiceYear = invoiceDate.getFullYear()
        const dueDate = this.$options.filters.formatDay(new Date(invoice.due_date))

        // Format the total amount using the global formatCurrency filter
        const formattedAmount = this.$options.filters.formatCurrency(invoice.total_amount)

        switch (invoice.status) {
          case 'Unpaid':
            this.invoiceMessage = `Invoice for ${invoiceMonth} ${invoiceYear} is ready. Total amount: Ksh ${formattedAmount}. Please settle by ${dueDate}`
            break
          case 'Overdue':
            this.invoiceMessage = `Alert: ${invoiceMonth} ${invoiceYear} Invoice overdue! Total amount: Ksh ${formattedAmount}. Immediate payment required to prevent service interruption.`
            break
          case 'Partially Paid':
            this.invoiceMessage = `Notice: ${invoiceMonth} ${invoiceYear} Invoice partially settled. Total amount: Ksh ${formattedAmount}. Complete the outstanding amount by ${dueDate}.`
            break
          default:
            this.hasInvoice = false
        }
      } else if (this.pendingInvoices.length > 1) {
        // Format the total amount expected using the global formatCurrency filter
        const formattedTotalAmount = this.$options.filters.formatCurrency(this.totalAmountExpected)
        this.invoiceMessage = `You have ${this.pendingInvoices.length} pending invoices. Total amount expected: Ksh ${formattedTotalAmount}.`
      }
    },
    handleViewInvoice() {
      if (this.pendingInvoices.length === 1) {
        this.viewInvoice(this.pendingInvoices[0].id)
      } else {
        // Redirect to billing page if there are multiple invoices
        this.$router.push('/settings?tab=service-charge-invoice')
      }
    },
    viewInvoice(id) {
      this.isLoadingViewInvoice = true
      axios.get(`/service-charge-invoices/${id}/download`, { responseType: 'blob' })
        .then(response => {
          const pdfBlob = response.data
          const pdfUrl = URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error viewing invoice:', error)
          this.$toast.error('An error occurred while loading the invoice')
        }).finally(() => {
          this.isLoadingViewInvoice = false
        })
    },
  },
}
</script>
